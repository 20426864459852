import type { FC } from 'react'
import { useRouter } from 'next/router'
import { CwsGrid, CwsGridCol, CwsGridRow, CwsSection } from '@components/cws'
import type { GetServerSideProps } from 'next'
import { getContentByContentTypeId, konakartMethodCall } from '@api/proxy'
import { always, compose, propOr, tryCatch } from 'ramda'
import { ProductsCollection } from '../../components/common/ProductsCollection/ProductsCollection'
import type { ImagePageBanner } from '@api/content'
import { STATIC_CONTENT_TYPES } from '@api/content'
import type { Locale } from '@model/locales'
import type { Banner } from '@pages/index'
import { transformContents } from '@pages/index'
import { TopBannerSlider } from '@components/home/TopBannerSlider'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import { MOBILE_BP } from '@app/constants'
import axios from 'axios'

interface ShipFrontPageProps {
  collections: string[]
  imageBanners: Omit<ImagePageBanner, 'title'>[]
  mobileBanners: Omit<ImagePageBanner, 'title'>[]
}

export const ShipFrontPage: FC<ShipFrontPageProps> = ({ collections, imageBanners, mobileBanners }) => {
  const {
    query: { catalogId },
  } = useRouter()
  const isMobile = useMediaLess(MOBILE_BP)

  return (
    <CwsSection className="cws-pt-xs">
      <CwsGrid>
        <CwsGridRow>
          <CwsGridCol col="12">
            {!isMobile && <TopBannerSlider imageBanners={imageBanners} />}
            {isMobile && <TopBannerSlider imageBanners={mobileBanners} />}
            <ProductsCollection collections={collections} />
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
    </CwsSection>
  )
}

export default ShipFrontPage

export const getServerSideProps: GetServerSideProps = async ({ res, req, locale, query }) => {
  try {
    const initCollections = await konakartMethodCall<string>({
      f: 'getConfigurationValue',
      key: 'DFSHOP_WELCOME_COMPONENTS',
    })

    const { catalogId } = query
    const isSiteMap = /\.xml/.test(catalogId as string)
    if (isSiteMap) {
      const EXTERNAL_DATA_URL = `${process.env.KONAKART_HOST}/${catalogId}`
      try {
        const request = await axios.get(EXTERNAL_DATA_URL)
        if (!request.data) {
          return {
            redirect: {
              destination: '/',
              permanent: false,
            },
          }
        }
        res.setHeader('Content-Type', 'text/xml')
        res.write(request.data)
        res.end()
        return {
          props: {
            collections: [],
            imageBanners: [],
            mobileBanners: [],
          },
        }
      } catch (e) {
        return {
          redirect: {
            destination: '/',
            permanent: false,
          },
        }
      }
    }
    let imageBanners: Omit<ImagePageBanner, 'title'>[] = []
    let mobileBanners: Omit<ImagePageBanner, 'title'>[] = []
    const storeId = process.env.KK_STORE || 'store1'

    if (catalogId && storeId === 'store1') {
      const bannersResponse = await getContentByContentTypeId(STATIC_CONTENT_TYPES['TOP_BANNER'], locale as Locale)

      const catalogPredicate = ({ searchKey }: Banner) =>
        typeof searchKey === 'string' && searchKey.includes(catalogId as string)

      const imageBannerTransform = ({ zipFile, clickUrl }: Banner) => {
        return {
          image: zipFile,
          clickUrl,
        }
      }

      const bannersCompare = (c1: Banner, c2: Banner) => (c1.custom3 || '').localeCompare(c2.custom3 || '')

      const iBanners = transformContents(bannersResponse) as Banner[]
      imageBanners = iBanners
        .filter(catalogPredicate)
        .filter(({ enabled }) => enabled)
        .sort(bannersCompare)
        .map(imageBannerTransform)

      const xsBanners = transformContents(bannersResponse) as Banner[]
      mobileBanners = xsBanners
        .filter(catalogPredicate)
        .filter(({ enabled }) => enabled)
        .sort(bannersCompare)
        .map(imageBannerTransform)
    }

    const parsedCollectionsData = compose(
      propOr([], storeId),
      tryCatch((json: string) => JSON.parse(json), always({}))
    )(initCollections)

    return {
      props: {
        collections: parsedCollectionsData,
        imageBanners,
        mobileBanners,
      },
    }
  } catch (e) {
    return {
      props: {
        collections: [],
        imageBanners: [],
        mobileBanners: [],
      },
    }
  }
}
